// $primary: #a51616;
$primary: #a70301; /* MAIN COLOR */
$secondary: #c4d2c8; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Share+Tech|Sirin+Stencil');

h1, h2, h3, h4 {
	font-family: 'Sirin Stencil', cursive;
}

p {
	font-family: 'Share Tech', sans-serif;
	font-size: 1.5em;
}




.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar {
	background-color: #fff;
}

.navbar .navbar-nav {
    > li > a {
    	font-size: 15px;
    	color: $primary;
		text-align: center;
		height: 140px;
		display: flex;
		align-items: center;

		&:focus, &:visited {
  		outline: 0;
  		color: $primary;
  		}

	    &:hover {
	    	color: darken($blk, 50%);
	    }

		@media (max-width: 767px) {
		    height: 35px;
		    display: inline-block;
		}


	}
}

.navbar-right {
	margin-top: 0px;
}
 
.navbar-toggle {
    margin: 25px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 767px) {
		margin-top: 35px;
	}
}


/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 40px 0px 20px;
	background: $primary;
	color: $footerLinks;

	p {
		font-size: 15px;
	}

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.clearfix {
    clear: both
}

.bannercontainer {
    width: 100%;
    position: relative;
    padding: 0
}
.bannercontainer .banner {
    width: 100%;
    position: relative
}

.btn {
  background-color: $primary;  
  // border-color: rgba(255,255,255,1);
  border-radius: 5px;
  font-size: 15px;
  padding: 1em 3em;
  color: $wht;

  &:focus:active {
  	outline: 0;
  	-webkit-box-shadow: none;
    box-shadow: none;
  }


 &:hover {
  		background-color: $secondary; 
  		color: $blk;
  	}
 }

.separator {
    height: 1px;
    background-color: $secondary;
    margin-bottom: 20px;
    width: 45px
}

.logo {
    max-width: 175px;
    width: 100%;
}

@media (max-width: 767px) {
	.logo {
		max-width: 150px;
	}
}

.content-section-b {
	h3 {
		font-size: 40px;
    	color: #blk;
	}

	p {
		line-height: 25px;
	}

	@media (max-width: 767px) {
		h3 {
			font-size: 30px;
		}
	}
}

.padDown {
	padding-top: 40px;
}

.heading-pad {
 	padding-bottom: 10px;
}

.triPad {
	padding-bottom: 25px;
	padding-top: 25px;
}

.padTop {
	padding-top: 50px;
}

.padBot {
	padding-bottom: 25px;
}

.content-section-c {
    background: rgba(167, 3, 1, 0.8);

    img {
    	padding: 20px;
    	border-radius: 25px;
    }

    h2 {
    	color: #fff;
    }

    p {
    	color: #fff;
    }
}

.content-section-d {

	 h4 {
	 	font-size: 50px;
	 	margin: 0;
	 	padding: 0px;
	 }

	p {

	}
}

.roll-effect {

	&:hover {
		 background-color: #f5f5f5
	}
}

.container-content-e {
  background: url('../img/banner.jpg') no-repeat;
  background-position: top center;
  background-attachment: fixed;
  background-size: cover;
  padding: 200px 20px;
  background-attachment: unset;
}

.box {
  background: rgba(167, 3, 1, 0.8);
  color: white;
  border-radius: 5px;
}

.box h1 {
    color: white;
}

@media (max-width: 767px) {
.feature-box.roll-effect h4 {
    padding: 15px 0px;
	}
}

.point {
    max-width: 150px;
}

